/* GENERIC */
a:link, a:visited, a:active, a:hover {
  color: #6C63FF;
}

h1 {
  background: linear-gradient(81.84deg, #6C63FF -9.4%, #63FFBA 51.57%, #6C63FF 84.07%, #63FFBA 90.59%);
  -webkit-background-clip: text;
  color: transparent;
  display: inline-block;
}

h5 {
  color: #989898;
  font-size: 16px;
  font-weight: bold;
}

header {
  background-color: #1a1c1e;
  color: #FFF;
  font-size: 28px;
  padding: 20px 0;
  align-items: center;
  justify-content: center;
}

header img {
  margin-right: 8px;
}

header .appName {
  display: inline-block;
  top: -9px;
  position: relative;
  font-size: 16px;
}

header h1 {
  margin: 20px 0;
}

header a {
  color: #FFF !important;
}

.app.home {
  height: 100%;
}

.app.home h1 {
  font-size: 64px;
}

.app.home h3 {
  font-size: 22px;
  color: #FFFFFF;
  font-weight: normal;
}

.wrapper {
  margin: auto;
  display: grid;
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  height: 100%;
  min-height: 100vh;
  padding: 0 40px;
}

.column.left {
  background-color: #1a1c1e;
}

.column.right {
  background-color: #63FFBA;
}

.column .text {
  padding-right: 100px;
}

.content {
  max-width: 1400px;
  margin: auto;
  padding: 0 20px;
}

.content-half {
  max-width: 700px;
}

.column.left .content-half {
  margin-left: auto;
  padding-right: 60px;
}

.column.right .content-half {
    margin-right: auto;
    padding-left: 60px;
}

.logo {
  margin: 50px 0 50px 0;
}

.promo-image {
  text-align: center;
  padding: 20px;
  margin-top: 50px;
}

.app.home .badges {
  margin-top: 40px;
}

.app.home .badge {
  margin-top: 10px;
}

/* LOCATION STYLES */
.app.location {
  margin-bottom: 50px;
}

.app.location header {
  min-height: 248px;
}

.app.location h1 {
  margin-bottom: 0;
  font-size: 42px
}

.app.location h2 {
  margin-top: 10px;
  font-size: 24px;
}

.app.location .content.block h2 {
  font-size: 28px;
}

.app.location h3 {
  color: #000;
  font-weight: bold;
  font-size: 18px;
}

.app.location h3 a {
  color: #000;
  text-decoration: none;
}

.app.location .content.block {
  margin-top: 30px;
}

.app.location .content.code code {
  background: #EEE;
  border-radius: 10px;
  display: inline-block;
  padding: 15px;
  margin-bottom: 10px;
  display: flex;
  max-width: 500px;
}

.app.location .content.code code img {
  width: 35px;
  height: 35px;
  margin-right: 10px;
  border-radius: 8px;
}

.app.location .badges {
  display: inline-block;
  margin: 20px 0 0 0;
}

.app.location .badge {
  display: inline-block;
  margin-right: 10px;
}

/* DASHBOARD */
.app.dashboard header {
  min-height: auto;
}

.app.dashboard h1 {
  margin: 0;
}

.app.dashboard h2 {
  font-size: 100px;
  margin: 5px 0;
  color: #CCC;
}

.app.dashboard .content {
  display: inline-block;
}

.app.dashboard .panel {
  width: 240px;
  _height: 240px;
  float: left;
  background: #EEE;
  padding: 20px;
  border-radius: 10px;
  margin: 0 5px 5px 0;
  text-align: center
}

@media only screen and (max-width: 1280px) {
  .app.home .promo-image img {
    width: 100%;
  }
}

@media only screen and (max-width: 1180px) {
  .app.home h1 {
    font-size: 48px;
  }
}

@media only screen and (max-width: 912px) {
  .column.left .content-half {
    margin-left: 0;
    margin-bottom: 50px;
  }

  .column {
    height: auto;
  }
}

@media only screen and (max-width: 820px) {
  .column {
    height: auto;
  }

  .app.home h1 {
    font-size: 48px;
    margin-top: 0;
  }

  .app.home .content-half, .app.home .content-half .text, .app.home .promo-image  {
    padding: 0;
  }

  .app.home .promo-image img {
    width: 300px;
  }

  .app.location header {
    min-height: auto;
  }

  .app.location  h1 {
    font-size: 32px;
  }

  .app.location h2 {
    font-size: 16px;
  }

  .app.location .content.block h2 {
    font-size: 26px;
  }

  .app.location .content.code code {
    padding: 10px 0 10px 10px;
    font-size: 11px;
  }

  .app.location .content.code code img {
    width: 22px;
    height: 22px;
  }

  .app.location .badges {
    margin-bottom: 0;
  }

  .app.location .badge {
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 768px) {
  .column {
    min-height: auto;
  }

  .app.dashboard .panel {
    width: 100%;
    height: auto;
    margin-right: 0;
    padding: 20px 0;
  }

  .app.dashboard .panel h2 {
    font-size: 62px;
  }

  .app.dashboard .panel h1, .app.dashboard .panel h4 {
    margin: 0;
  }

}
